import React from 'react'
import { graphql } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { contentfulReactComponentsOptions } from '~constants/styles'

import Layout from '~components/layout'
import SEO from '~components/seo'

const GenericPage = props => {
  const { section, locale } = props.pageContext
  const { location } = props

  const page = props.data.contentfulGenericPage

  return (
    <Layout locale={locale} location={location} section={section}>
      <SEO lang={locale} title={page?.metaTitle || page.title} description={page?.metaDescription} />
      <div className='max-w-page pt-4 lg:py-8'>
        <div className='headline-1'>{page.title}</div>
        <div className='mt-4 contentful'>
          {documentToReactComponents(page?.body?.json, contentfulReactComponentsOptions)}
        </div>
      </div>
    </Layout>
  )
}

export default GenericPage

export const genericPageQuery = graphql`
  query genericPageQuery($id: String!) {
    contentfulGenericPage(id: { eq: $id }) {
      title
      metaTitle
      metaDescription
      body {
        json
      }
    }
  }
`
